<template>
  <div class="TutorDetai">
    <div class="TutorDetaiBox">
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: 'ViewTutor?id=' + enterpriseId }"
            >导师列表</el-breadcrumb-item
          >
          <el-breadcrumb-item>导师详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="Detail" v-loading="loading">
        <div class="flex title-Bgc">
          <el-avatar :src="Resume.Avatar || defaultImg" :size="104">
          </el-avatar>
          <div class="ml-24 u-flex-1 flex-column mr-24">
            <div>
              <span class="fs-16">{{ Resume.DisplayName }}</span>
              <span :class="['resume-avatar-gender']">{{
                staffInfo.Name
              }}</span>
            </div>
            <!-- <div class="mt-12 fs-12">
              <span>{{ formartDate(Resume.BirthDay, "yyyy-MM") }}</span>
            </div> -->
            <el-row class="mt-8 fs-12">
              <el-col :span="8">
                <span>所属企业：{{ Resume.ExpectJobName }}</span>
              </el-col>
              <el-col :span="8">
                <span
                  >导师类型：{{
                    staffInfo.Speciality ? staffInfo.Speciality : ""
                  }}</span
                >
              </el-col>
              <!-- <el-col :span="8">
            <span>期望地点：{{ Resume.ExpectWorkPlace }}</span>
          </el-col> -->
            </el-row>
            <el-row class="mt-8 fs-12">
              <el-col :span="8">
                <span
                  >职称：{{
                    staffInfo.Position ? staffInfo.Position : ""
                  }}</span
                >
              </el-col>
              <el-col :span="10">
                <span>联系邮箱：{{ staffInfo.Email }}</span>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="detai-content">
          <CardHeader :title="'担任课程'" :pt="0" :pb="16" />
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="item-txt">
                {{ staffInfo.Course ? staffInfo.Course : " " }}
              </div>
            </el-col>
          </el-row>
          <CardHeader :title="'专业特长'" :pt="0" :pb="16" />
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="item-txt">
                {{ staffInfo.Speciality ? staffInfo.Speciality : "" }}
              </div>
            </el-col>
          </el-row>
          <CardHeader :title="'个人简介'" :pt="0" :pb="16" />
          <el-row :gutter="20">
            <el-col :span="12">
              <div
                class="item-txt"
                v-html="staffInfo.Description ? staffInfo.Description : ''"
              ></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardHeader from "@/components/Common/CardHeader.vue";
import { dateTimeFilter } from "@/filters";
import { getInfo as getStaffInfo } from "@/api/enterprise/enterpriseStaff";
export default {
  name: "FirmDetail",
  props: {},
  components: {
    CardHeader
  },
  data() {
    return {
      loading: false,
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      defaultImg: require("@/assets/images/student/default2.png"),
      id: this.$route.query.id,
      staffInfo: {},
      Resume: {}
    };
  },
  computed: {
    formartDate() {
      return (data, formate) => dateTimeFilter(data, formate);
    }
  },
  watch: {},
  methods: {
    // 导师详情
    async getStaffInfo() {
      // console.log("导师详情", this.id);
      this.loading = true;
      try {
        const { Result } = await getStaffInfo(this.id);
        this.staffInfo = Result;
        // console.log("导师详情", this.staffInfo);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    }
  },
  created() {
    this.getStaffInfo();
  },
  mounted() {}
};
</script>

<style scoped lang="scss">
.TutorDetai {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
}
.TutorDetaiBox {
  box-sizing: border-box;
  width: 1100px;
  margin: 0 auto;
  padding-bottom: 40px;
  .breadcrumb {
    box-sizing: border-box;
    width: 100%;
    height: 46px;
    padding: 16px 0 8px;
  }
  .Detail {
    width: 100%;
    height: 100%;
    min-height: 500px;
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    .title-Bgc {
      box-sizing: border-box;
      height: 168px;
      background: #5a6cb9;
      padding: 32px 130px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      .resume-avatar-gender {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 28px;
      }
    }
    .detai-content {
      min-height: 400px;
      display: flex;
      padding: 40px 130px;
      flex-direction: column;
      .item-txt {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
        &:nth-child(1) {
          min-height: 88px;
        }
        &:nth-child(2) {
          min-height: 88px;
        }
        &:nth-child(3) {
          min-height: 114px;
        }
      }
    }
  }
}
</style>
